import * as React from "react"
import { Helmet } from "react-helmet"

// Import Components
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="MyWebvite - Printing invites is a hassle. Send a Webvite instead." />
    <Helmet>
      <meta name="title" content="MyWebvite" />
      <meta name="description" content="Printing invites is a hassle. Send a Webvite instead." />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://mywebvite.com" />
      <meta property="og:title" content="MyWebvite" />
      <meta property="og:description" content="Printing invites is a hassle. Send a Webvite instead." />
      <meta property="og:image" content="https://mywebvite.com/MyWebvite-Preview.png" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://mywebvite.com" />
      <meta property="twitter:title" content="MyWebvite" />
      <meta property="twitter:description" content="Printing invites is a hassle. Send a Webvite instead." />
      <meta property="twitter:image" content="https://mywebvite.com/MyWebvite-Preview.png" />
    </Helmet>
    <div className="section no-top-bottom">
      <div className="container">
        <h1 className="no-top-bottom">
          Printing invites<br/>
          is a hassle.<br/>
          Send a Webvite<br/>
          instead.
        </h1>
      </div>
    </div>
    <div className="section">
      <div className="container">
        <div className="section no-top-bottom">
          <p className="no-top-bottom" style={{maxWidth:"1200px"}}>
            Phew, you’re ready. You’ve worked hard and finalized all the details for your special event. Now you need to get the word out, but how?<br/><br/>
            Do you spend a wild amount of money and ‘somehow’ collect everyone’s physical address, choose a #standard design template, research a printer and finally send them out hoping they arrive?<br/><br/>
            Or you go with MyWebvite — A streamlined process of digitally sending a custom-made invite that is tailored specifically to you. From baby showers to weddings, MyWebvite can be your one-stop-shop for sending out a stunning invitation. 
          </p>
        </div>
      </div>
    </div>
    <div className="section no-top">
      <div className="container">
        <p className="small no-top-bottom" style={{maxWidth:"430px"}}>
          <span className="font-weight-700">Side Note:</span> Check back here soon as more details will be revealed or if you can’t wait, send an email to find out more.
        </p>
        <div className="link-box no-bottom">
          <a href="mailto:mywebvite@gmail.com" target="_blank" className="btn">Send An Email</a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
